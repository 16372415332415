import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "../SortableItem/SortableItem";
import "./Droppable.css";
import { MdSave } from 'react-icons/md';
import { MdClose } from "react-icons/md";
import saveDashboardCards from "../../../api/Dashboard/saveDashboardCards";

import React, { useEffect, useState } from "react";
import GetPermission from "../../../auth/GetPermission";

const Droppable = ({ id, items, handleCardDetails, cardType, languageCode, currency, language, currentDashboard, programFromDate, programToDate, compareFromDate, compareToDate,
    setIsLoading, showModal, setShowModal, isDraggable, setIsDraggable, isMoreCards, setIsRealTime, dateState, items1,
    items2 }) => {
    const { setNodeRef } = useDroppable({ id });
    const [draggingItemId, setDraggingItemId] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;

    const toggleDraggable = () => {
        setIsDraggable((prev) => !prev);
    };

    useEffect(() => {
        if (isDraggable && dateState === 0) {
            setIsRealTime(0);
        } else if (!isDraggable && dateState === 0) {
            setIsRealTime(1);
        }
    }, [isDraggable])

    const saveCards = async () => {
        setIsLoading(true);
        let cards = {};
        let body = {};
        switch (currentDashboard) {
            case 'TVMDashboardAlarms':
                cards = items.map(card => ({ id: card.id }));
                body = {
                    tenantsDB: currentTenant,
                    alarmCard: cards
                };
                break;
            case 'TVMDashboardTransactions':
                cards = items.map(card => ({ id: card.name }));
                body = {
                    tenantsDB: currentTenant,
                    financialCard: cards
                };
                break;
            case 'GeneralDashboard':
                
                const alarmCards = items1.dashboard1.map(card => ({ id: card.id }));
                const financialCards = items2.dashboard3.map(card => ({ id: card.name }));
                body = {
                    tenantsDB: currentTenant,
                    alarmCard: alarmCards,
                    financialCard: financialCards
                };
                break;
        }
        try {
            const response = await saveDashboardCards(body);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            {
                GetPermission(currentUser, currentDashboard, "updateAllow") &&
                <div className="dragAndDrop-cards-btn">
                    <button onClick={toggleDraggable} className="toggle-drag-button">
                        {isMoreCards ? (
                            <MdClose size={20} />
                        ) : isDraggable ? (
                            <MdSave size={20} 
                            onClick={saveCards} 
                            />
                        ) : (
                            <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
                        )}
                    </button>
                </div>
            }
            <SortableContext id={id} items={items} strategy={rectSortingStrategy} key={id}>
                <div ref={setNodeRef} className={`droppable ${draggingItemId ? 'droppable-over' : ''}`}>
                    {items.map((item) => (
                        (<SortableItem
                            key={item.id}
                            id={item.id}
                            item={item}
                            cardType={cardType}
                            handleCardDetails={handleCardDetails}
                            languageCode={languageCode}
                            currency={currency}
                            language={language}
                            onDraggingChange={setDraggingItemId}
                            isDraggable={isDraggable}
                            programFromDate={programFromDate}
                            programToDate={programToDate}
                            compareFromDate={compareFromDate}
                            compareToDate={compareToDate}
                            setIsLoading={setIsLoading}
                            showModal={showModal}
                            setShowModal={setShowModal}
                        />)
                    ))}
                </div>
            </SortableContext>
        </>
    );
};

export default Droppable;
