import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './css/CreateUser.css';

import getAllRoles from '../../api/Roles/getAllRoles';
import createUser from '../../api/EMSuser/createUser';
import editUser from '../../api/EMSuser/editUser';
import getPermission from '../../auth/GetPermission';
import getAllLanguage from '../../api/Languages/getAllLanguage';
import GetUser from '../../api/EMSuser/getUser';
import CurrentLanguageFile from '../../Hooks/language/CurrentLanguageFile';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { Spinner } from 'react-bootstrap';
import ErrorCases from '../../Common/ErrorCases';

function CreateUserModal({ show, onHide, editedUserData, editing }) {
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState();
    const [language, setLanguage] = useState("");
    const [data, setData] = useState([]);
    const [validNumber, setValidNumber] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [receiveNotifications, setReceiveNotifications] = useState(true);
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const tenantsOrigin = currentUser.data.userData.tenantsOrigin;
    const [roleId, setRoleId] = useState("");
    const navigate = useNavigate();
    const [editingUserId, setEditingUserId] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [isChangeEmail, setIsChangeEmail] = useState(false);
    const [isChangePhone, setIsChangePhone] = useState(false);
    const languageFile = JSON.parse(localStorage.getItem('language'));
    const [languagesData, setLanguagesData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        async function editingMode() {
            if (editing) {
                setIsLoading(true);
                try {
                    setIsEditing(true);
                    const editingUser = editedUserData;
                    setUsername(editingUser.name);
                    setEmail(editingUser.email);
                    setPhone(editingUser.phone);
                    setLanguage(editingUser.languageId);
                    setRoleId(editingUser.roleId);
                    setEditingUserId(editingUser.id);
                } catch (error) {
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsEditing(false);
            }
        }

        async function fetchData() {
            try {
                const response = await getData();
                setData(response.roles);
            } catch (error) {
                console.log(error);
            }
        }
        clearData();
        editingMode();
        fetchData();
        getLanguages();
    }, [show]);

    async function getData() {
        const { data, error } = await getAllRoles(currentTenant);
        if (error !== undefined) {
            return [];
        } else {
            return data;
        }
    }

    async function getLanguages() {
        const languages = await getAllLanguage(currentTenant);
        setLanguagesData(languages.data);
    };

    const handlePhoneChange = (value, data) => {
        setPhone(value);
        setValidNumber(validateNumber(value.slice(data.dialCode.length)));
    };

    const validateNumber = number => {
        const phoneNumberPattern = /^\d{10}$/;
        return phoneNumberPattern.test(number);
    }

    const handleClickSave = async () => {
        setError('');
        setIsLoading(true);
        
        try {
            if (isEditing) {
                const dataUser = {
                    name: username,
                    email: email,
                    phone: isEditing ? phone : "+" + phone,
                    languageId: parseInt(language),
                    notifications: receiveNotifications ? 1 : 0,
                    roleId: parseInt(roleId),
                    tenantsDB: currentTenant
                }
                const response = await editUser(dataUser, editingUserId);
                if (response.error !== undefined) {
                    const error = ErrorCases(response.error);
                    setError(error);
                    return;
                }
                if (currentUser.data.userData.name === username) {
                    currentUser = await GetUser();
                    await CurrentLanguageFile(currentUser);
                    localStorage.setItem('currentLanguage', currentUser.data.userData.languageAbbreviation);
                    localStorage.setItem('currentUser', JSON.stringify(currentUser));
                }
                onHide();

                clearData();
                if (currentUser.data.userData.name === username) {
                    navigate(0);
                }
            } else {
                const dataUser = {
                    username: username,
                    email: email,
                    phone: "+" + phone,
                    languageId: parseInt(language),
                    notifications: receiveNotifications ? 1 : 0,
                    roleId: parseInt(roleId),
                    tenantsDB: currentTenant
                }
                try {
                    const response = await createUser(dataUser);
                    if (response.error !== '') {
                        const error = ErrorCases(response.error);
                        setError(error);
                        return;
                    }
                    onHide();
                    navigate(0);
                    clearData();
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    function handleChangeEmail() {
        setIsChangeEmail(true);
        setIsChangePhone(false);
        setShowModal(true);
    }

    const handleChangePhone = () => {
        setIsChangePhone(true);
        setIsChangeEmail(false);
        setShowModal(true);
    }

    const clearData = () => {
        setUsername('');
        setEmail('');
        setPhone('');
        setLanguage('');
        setRoleId('');
        setEditingUserId('');
        setValidNumber(true);
    };

    const handleClose = () => {
        onHide();
        clearData();
        setError('');
    };

    return (
        <Modal show={show} onHide={onHide} className='modal' centered>
            <Modal.Header>
                <Modal.Title>{isEditing ? languageFile.EditUser : languageFile.CreateUSer}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='input__label'>{languageFile.UserName}</div>
                <input placeholder={languageFile.EnterYourUserName} className='module__input-search'
                    onChange={evt => setUsername(evt.target.value)} value={username} required disabled={isEditing}></input>
                <div className='input__label'>{languageFile.Email}</div>
                <input placeholder={languageFile.EnterYourEmailAddres} className='module__input-search'
                    onChange={evt => setEmail(evt.target.value)} value={email} type='email' required disabled={isEditing}></input>
                {currentUser.data.userData.name === username && tenantsOrigin === 1 &&
                    <Button className="small-button primary" onClick={handleChangeEmail} style={{ fontSize: '10px' }}>{languageFile.ChangeEmail}</Button>
                }
                <div className='input__label'>{languageFile.Phone}
                    {!validNumber && <span className='inputs__invalid-number-alert'>{languageFile.TenDigitNumber}</span>} </div>
                <PhoneInput placeholder={languageFile.EnterYourPhoneNumber} className='module__input-search'
                    onChange={handlePhoneChange} value={phone} type='tel' inputProps={{ required: true }}
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" country={"us"} disabled={isEditing}></PhoneInput>
                {currentUser.data.userData.name === username && tenantsOrigin === 1 &&
                    <Button className="small-button primary" onClick={handleChangePhone} style={{ fontSize: '10px' }}>{languageFile.ChangePhone}</Button>
                }
                <div className='inputs__inline-elements'>
                    <div className='input__box'>
                        <div className='input__label'>{languageFile.Language}</div>
                        <select className='module__input-search'
                            onChange={evt => setLanguage(evt.target.value)} value={language} type='select' required disabled={isEditing && currentUser.data.userData.name !== username}>
                            <option value="">{languageFile.SelectYourLanguage}</option>
                            {
                                languagesData.map(language => {
                                    return (<option key={language.id} value={language.id}>{languageFile[language.name]}</option>)
                                })
                            }
                        </select>
                    </div>
                    <div className='input__box'>
                        <div className='input__label'>{languageFile.Role}</div>
                        <select className='module__input-search'
                            onChange={evt => setRoleId(evt.target.value)} value={roleId} type='select' required disabled={isEditing && !getPermission(currentUser, "UMUsers", "updateAllow")}>
                            <option value="">{languageFile.SelecARole}</option>
                            {
                                data.map(item => {
                                    return <option key={item.id} value={item.id}>{item.name}</option>
                                })
                            }
                        </select>
                    </div>
                </div>
                <div className='error-alert'>{error}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleClose} className='general-button danger'>
                    {languageFile.Cancel}
                </Button>
                <Button onClick={handleClickSave} className='general-button primary'>
                    {languageFile.Save}
                </Button>
            </Modal.Footer>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </Modal>
    )
}

export default CreateUserModal;