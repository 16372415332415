import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';

import GetAssignedTenants from '../../api/Tenants/getAssignedTenants';
import GetAllTenants from '../../api/Tenants/getAllTenants';
import GetPermission from '../../auth/GetPermission';
import GetUser from '../../api/EMSuser/getUser';
import { Spinner } from 'react-bootstrap';

function TenantsChangeModal({ show, onHide }) {
    const language = JSON.parse(localStorage.getItem('language'));
    const [error, setError] = useState(null);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const idCognito = currentUser.data.tenantsUser[0].userId;
    const currentTenant = currentUser.data.currentTenant;
    const [tenantsData, setTenantsData] = useState([]);
    const [changeTenantsOptions, setChangeTenantsOptions] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState('');
    const [currentTenantName, setCurrentTenantName] = useState('');
    const [hasChanged, setHasChanged] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const tenantsResponse = await getTenantsData();
                setTenantsData(tenantsResponse.tenants);
                const assignedResponse = await getAssigned();
                setTenantsSelect(assignedResponse.tenants);
                setSelectedTenant(tenantsResponse.tenants.find(item => item.tenantsDBName === currentTenant).tenantsName);
                setCurrentTenantName(tenantsResponse.tenants.find(item => item.tenantsDBName === currentTenant).tenantsName);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, []);

    const changeSelectedTenant = (tenant) => {
        setSelectedTenant(tenant);
        const newTenant = tenantsData.find(item => item.tenantsName === tenant).tenantsDBName;
        newTenant === currentTenant ? setHasChanged(false) : setHasChanged(true);
    };

    const setTenantsSelect = (tenants) => {
        const tenantsSelect = tenants.filter(tenant => tenant.assignmentStatus === 1);
        setChangeTenantsOptions([...tenantsSelect]);
    };

    async function getAssigned() {
        const { data, error } = await GetAssignedTenants(currentTenant, idCognito);
        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getTenantsData() {
        const { data, error } = await GetAllTenants(currentTenant);
        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleChangeCurrentTenant = async () => {
        try {
            setIsLoading(true);
            const newTenant = tenantsData.find(item => item.tenantsName === selectedTenant).tenantsDBName;
            const updatedCurrentUser = await GetUser(newTenant);
            localStorage.setItem('currentUser', JSON.stringify(updatedCurrentUser));
            navigate(0);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Modal show={show} onHide={onHide} centered >
                <Modal.Header className='modal-header'>
                    <Modal.Title >{language.ChangeTenant}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal__denomination'>{language.CurrentTenant}: <span className='input__label'>{currentTenantName}</span></div>
                    <div className='input__label'>{language.SelectANewTenant}</div>
                    <select className='module__input-search'
                        onChange={evt => changeSelectedTenant(evt.target.value)}
                        value={selectedTenant}
                        disabled={!GetPermission(currentUser, "UMTenants", "updateAllow")}
                    >
                        {changeTenantsOptions.length > 0 && changeTenantsOptions.map(tenant => {
                            return (
                                <option value={tenant.tenantsName} key={tenant.tenantsName}>{tenant.tenantsName}</option>
                            )
                        })}
                    </select>
                </Modal.Body>
                <Modal.Footer>
                    <button className='general-button danger' onClick={onHide}>
                        {language.Cancel}
                    </button>
                    <button className={`general-button ${hasChanged ? 'primary' : 'disabled'}`} onClick={handleChangeCurrentTenant} disabled={!hasChanged}>
                        {language.Save}
                    </button>
                </Modal.Footer>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
};

export default TenantsChangeModal;