import { useRef } from 'react';

import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import QR from '../../Common/QR';

import ReactToPrint from 'react-to-print';

import { AiFillDollarCircle } from "react-icons/ai";

function TicketQrModal({ show, onHide, ticket }) {
    const componentRef = useRef();
    const language = JSON.parse(localStorage.getItem('language'));

    return (
        <Modal show={show} onHide={onHide} centered >
            <Modal.Header className='modal-header'>
                <Modal.Title >{language.QRCode}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div ref={componentRef} className='change-ticket'>
                    <div className='ticket-data'>
                        <div className='ticket-logo'>
                            <img src={ticket.logo} className='ticket-logo'/>
                        </div>
                        <div className='ticket-balance'>
                            <AiFillDollarCircle size={100} />
                            <div className='ticket-balance__value'>
                                <div className='ticket-title'>{language.ChangeTicket}</div>
                                <div className='ticket-change-value'>{language.Value}: {ticket.balance}</div>
                            </div>
                        </div>
                        <div>{language.Agency}: {ticket.agencyName}</div>
                        <div>{language.TransactionId}: {ticket.transactionId}</div>
                        <div>{language.TvmId}: {ticket.tvmId}</div>
                        <div>{language.TicketNumber}: {ticket.ticketNumber}</div>
                        <div>{language.Issued}: {ticket.Issued}</div>
                        <div>{language.Expires}: {ticket.expiration}</div>
                    </div>
                    <QR value={ticket.Code} />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='general-button danger' onClick={onHide}>
                    {language.Cancel}
                </Button>
                <ReactToPrint
                    trigger={() => {
                        return <Button className='general-button primary'>
                            {language.Print}
                        </Button>
                    }}
                    content={() => componentRef.current}
                    documentTitle={language.QRCode}
                    pageStyle='print'
                />
            </Modal.Footer>
        </Modal>
    );
};

export default TicketQrModal;