import postMethod from "../../Hooks/API/post";

async function saveDashboardCards(body) {  
    const path = '/dashboard/TVM/SaveSettings';

    try {
        const data = await postMethod(body,path);
        return data;
    } catch (error) {
        return error;
    }
} 

export default saveDashboardCards;