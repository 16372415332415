import React, { useEffect, useRef, useState } from "react";
import { IoIosSettings } from "react-icons/io";

import { Modal, Form, Button, Spinner } from "react-bootstrap";

import {
    DndContext,
} from "@dnd-kit/core";


import "./Dashboard.css";

import Droppable from "./Droppable/Droppable";
import generalDashBoard from "../../api/Dashboard/generalDashboard";
import getDetailInfo from "../../api/Dashboard/getDetailInfo";

import DashBoardTable from "./Table/DashBoardTable";
import DashboardTableAlarm from "./Table/DashboardTableAlarm";
import DashboardDetailAlarmTable from "./Table/DashboardDetailAlarm";
import DashboardTableTotalAlarm from "./Table/DashboardTableTotalAlarm";
import useDragAndDrop from "../../Hooks/useDragAndDrop/useDragAndDrop";
import handleDateChange from "../../Common/HandleDateChange";
import handleTimeChange from "../../Common/HandleTimeChange";
import getHour from "../../Common/GetHour";
import getDate from "../../Common/GetDate";
import Footer from "../../Common/Footer";

function DashboardAlarm() {
    const [isDraggable, setIsDraggable] = useState(false);

    const formatDate = (date, languageCode) => {
        return date.toLocaleDateString(languageCode, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };

    const DateState = {
        CURRENTLY: 0,
        TODAY: 1,
        WEEK_DATE: 2,
        YEAR_TO_DATE: 3,
        PROGRAM_TO_DATE: 4,
        CUSTOM_DATES: 5
    };

    const ModalType = {
        MODAL_CARD_DETAILS: 0,
        MODAL_TABLE_DETAILS: 1
    }

    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);

    const [allAlarm, setAllAlarm] = useState([]);
    const [totalAlarm, setTotalAlarms] = useState([]);
    const [headerAllAlarm, setHeaderAllAlarm] = useState([]);
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showModalDetailsTable, setShowModalDetailsTable] = useState(false);
    const [isRealTime, setIsRealTime] = useState(1);
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [customDate, setCustomDate] = useState(false);
    const [dateState, setDateState] = useState(DateState.CURRENTLY);
    const [closeAlert, setCloseAlert] = useState(false);
    const [dateBtn, setDateBtn] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const initialItems = { dashboard1: [], dashboard2: [] };
    const { items, sensors, handleDragOver, handleDragEnd, setItems } = useDragAndDrop(initialItems);
    // #region get localStorage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const languageCode = currentUser.data.userData.languageCode;
    const language = JSON.parse(localStorage.getItem('language'));
    const generalAlarmSendingDelayInSeconds = currentUser.data.catalog.globalConfigTVM[0].generalAlarmSendingDelayInSeconds;
    // #endregion

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tenantsDB = currentTenant;
                let response;
                if (dateState === 5) {
                    response = await generalDashBoard({
                        tenantsDB,
                        isRealTime,
                        toDate: toDate.toISOString(),
                        fromDate: fromDate.toISOString()
                    });
                } else {
                    const isRealTimeValue = dateState === 0 ? 1 : isRealTime;
                    response = await generalDashBoard({ tenantsDB, isRealTime: isRealTimeValue, toDate, fromDate });
                }
                let {
                    DashboardCardAlarms: dashboardCardAlarms = [],
                    DashboardCardAlarmsUnorder: dashboardCardAlarmsUnorder = [],
                    AllTvmDashBoardAlarms: allTvmDashBoardAlarms = [],
                    totalAlarms: dashboardTotalAlarms = 0,
                } = response || {};

                if (Object.keys(dashboardCardAlarmsUnorder).length === 0) {
                    const dashboardEntries = Object.entries(dashboardCardAlarms);
                    const first15Entries = dashboardEntries.slice(0, 15);
                    const remainingEntries = dashboardEntries.slice(15);

                    dashboardCardAlarms = Object.fromEntries(first15Entries);
                    dashboardCardAlarmsUnorder = Object.fromEntries(remainingEntries);
                }

                const { data, header } = allTvmDashBoardAlarms;
                const translatedData = data ? translateData(data) : [];
                
                setAllAlarm(translatedData);
                setHeaderAllAlarm(header);
                setTotalAlarms(dashboardTotalAlarms);

                const dashboards = [[], []];
                let alarmsCount = 0;

                const processAlarms = (alarmsData, dashboardIndex) => {
                    Object.entries(alarmsData).forEach(([key, alarms]) => {
                        alarms.forEach((alarm) => {
                            dashboards[dashboardIndex].push({
                                ...alarm,
                                id: key,
                            });
                            alarmsCount++;
                        });
                    });
                };

                processAlarms(dashboardCardAlarms, 0);
                processAlarms(dashboardCardAlarmsUnorder, 1);

                const [dashboard1, dashboard2] = dashboards;

                setItems({ dashboard1, dashboard2 });
            } catch (err) {
                console.error(err);
            } 
        };

        fetchData();
        const intervalId = setInterval(() => {
            if (isRealTime) {
                fetchData();
            }
        }, (generalAlarmSendingDelayInSeconds ? generalAlarmSendingDelayInSeconds : 300) * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [dateState, closeAlert, isRealTime]);

    const handleCardDetails = async (idDevice, typesOfDetails) => {
        try {
            const tenantsDB = currentTenant;
            const id = idDevice;
            const { data, error } = await getDetailInfo({ id, isRealTime, toDate, fromDate, tenantsDB, typesOfDetails })
            if (data) {
                setData(data);
                setShowModal(true);
                setCloseAlert(false);
            } else if (error) {
                setError(error);
                setShowModal(false);
            }
        } catch (err) {
            setError(err);
            console.error(err);
            setShowModal(false);
        }
    };

    const handleDetails = async (idTVM, columnId, rowData) => {
        const tenantsDB = currentTenant;
        try {
            if (columnId === 'totalAlarm') {
                const { data, error } = await getDetailInfo({ id: idTVM, isRealTime, toDate, fromDate, tenantsDB, typesOfDetails: 'alarm_default' })
                if (data) {
                    setData(data);
                    setShowModalDetailsTable(true);
                } else if (error) {
                    setError(error);
                    setShowModalDetailsTable(false);
                }
            }
        } catch (err) {
            setError(err);
            console.error(err);
            setShowModalDetailsTable(false);
        }
    };

    const closeDeviceCode = (deviceCode) => {
        return (deviceCode === 'NotificationPanic' || deviceCode === 'PanicButton');
    };

    const handleModalClose = (modalType, deviceCode) => () => {
        if (modalType === ModalType.MODAL_TABLE_DETAILS) {
            setShowModalDetailsTable(false);
        } else {
            if (closeDeviceCode(deviceCode)) {
                setCloseAlert(true);
            }
            setShowModal(false);
        }
    };

    const handleCurrently = () => {
        setFromDate('');
        setToDate('');
        setIsRealTime(1);
        setCustomDate(false);
        setDateState(DateState.CURRENTLY);
        setDateBtn("");
    }

    const handleToday = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        //UTC date
        const startOfDayUTC = startOfDay.toISOString();
        const endOfDayUTC = endOfDay.toISOString();
        setFromDate(startOfDayUTC);
        setToDate(endOfDayUTC);
        setIsRealTime(0);
        setDateState(DateState.TODAY);
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfDay, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay, languageCode)}`
        );
    }

    const handleWeekDate = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfWeek.toISOString();
        const endOfWeekUTC = lastDayOfWeek.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setIsRealTime(0);
        setDateState(DateState.WEEK_DATE);
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek, languageCode)}`
        );
    }

    const handleYearToDate = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //Local date
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        const lastDayOfYear = currentDate;
        lastDayOfYear.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfYear.toISOString();
        const endOfWeekUTC = lastDayOfYear.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setIsRealTime(0);
        setDateState(DateState.YEAR_TO_DATE);
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfYear, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear, languageCode)}`
        );
    }

    const handleCustomDates = () => {
        setToDate(() => {
            const initialDate = new Date();
            initialDate.setHours(23, 59, 59, 999);
            return initialDate;
        });
        setFromDate(() => {
            const initialDate = new Date();
            initialDate.setHours(0, 0, 0, 0);
            return initialDate;
        });
        setCustomDate(true);
        setIsRealTime(0);
        setDateBtn('');
    }

    const handleFromDateChange = (event) => {
        const fromDate = event.target.value
        const date = new Date(fromDate);
        date.setHours(0, 0, 0, 0);
        //UTC date
        const startOfWeekUTC = date.toISOString();
        setFromDate(startOfWeekUTC);
    };

    const handleToDateChange = (event) => {
        const toDate = event.target.value;
        const date = new Date(toDate);
        date.setHours(23, 59, 59, 999);
        //UTC date
        const endOfWeekUTC = date.toISOString();
        setToDate(endOfWeekUTC);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const selectedFromDate = fromDateRef.current.value;
        const selectedToDate = toDateRef.current.value;
        //select date
        const [yearFrom, monthFrom, dayFrom] = selectedFromDate.split('-');
        const [hoursFrom, minutesFrom, secondsFrom] = getHour(new Date(fromDate)).split(':');
        const [yearTo, monthTo, dayTo] = selectedToDate.split('-');
        const [hoursTo, minutesTo, secondsTo] = getHour(new Date(toDate)).split(':');
        const customFromDate = new Date(yearFrom, monthFrom - 1, dayFrom, hoursFrom, minutesFrom, secondsFrom, 0);
        const customToDate = new Date(yearTo, monthTo - 1, dayTo, hoursTo, minutesTo, secondsTo, 999);

        setIsRealTime(0);
        setDateState(DateState.CUSTOM_DATES);
        setDateBtn(`
            ${language.FromDate}: ${formatDate(customFromDate, languageCode)}
            ${language.ToDate}: ${formatDate(customToDate, languageCode)}`
        );
    };

    const translateData = (data) => {
        const updatedData = data.map(item => {
            const updatedItem = {};
            Object.keys(item).forEach(key => {
                if (item[key] === 'Online') {
                    updatedItem[key] = language.Online;
                } else if (item[key] === 'Offline') {
                    updatedItem[key] = language.Offline;
                } else if (item[key] === 'Equal to') {
                    updatedItem[key] = language.Equalto;
                } else if (item[key] === 'Close') {
                    updatedItem[key] = language.Close;
                } else if (item[key] === 'Open') {
                    updatedItem[key] = language.Open;
                } else if (item[key] === 'More than') {
                    updatedItem[key] = language.Morethan;
                } else if (item[key] === 'Full') {
                    updatedItem[key] = language.Full;
                } else if (item[key] === 'Empty') {
                    updatedItem[key] = language.Empty;
                } else if (item[key] === 'Out paper') {
                    updatedItem[key] = language.Outpaper;
                } else if (item[key] === 'Low paper') {
                    updatedItem[key] = language.Lowpaper;
                } else if (item[key] === 'unknown') {
                    updatedItem[key] = language.unknown;
                } else {
                    updatedItem[key] = item[key];
                }
            });

            return updatedItem;
        });
        return updatedData;
    };

    return (
        <>
            <section className="main-container-app" style={{ minHeight: 'auto' }}>
                <div className="main-box">
                    <h4 className='dashboard-subtitle'>{language.TVM}</h4>
                    {totalAlarm.length > 0 && headerAllAlarm.length > 0 && (
                        <DashboardTableTotalAlarm device_data={totalAlarm} header={headerAllAlarm} language={language} />
                    )}
                    {(!headerAllAlarm  || headerAllAlarm.length === 0) && (
                            <p className="date">{language.NoData}</p>
                        )}
                </div>
            </section>
            <section className="main-container-app" style={{ minHeight: 'auto' }}>
                <DndContext
                    sensors={sensors}
                    onDragEnd={handleDragEnd}
                    onDragOver={handleDragOver}
                >
                    <div className="dashboard-container">
                        <div className="dashboard-btn-container">
                            <button onClick={handleCurrently} className="dashboard-date-btn">{language.Currently}</button>
                            <button onClick={handleToday} className="dashboard-date-btn">{language.Today}</button>
                            <button onClick={handleWeekDate} className="dashboard-date-btn">{language.WeekDate}</button>
                            <button onClick={handleYearToDate} className="dashboard-date-btn">{language.YearToDate}</button>
                            <button onClick={handleCustomDates} className="dashboard-date-btn">{language.CustomDates}</button>
                        </div>
                        {
                            (customDate) && (<div className="dashboard-box-date">
                                <Form>
                                    <Form.Group controlId="fromDate">
                                        <Form.Label>{language.FromDate}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            ref={fromDateRef}
                                            onChange={event => handleDateChange(event, fromDate, setFromDate)}
                                            value={fromDate ? getDate(fromDate) : ''}
                                        />
                                        <Form.Control
                                            type="time"
                                            onChange={event => handleTimeChange(event, fromDate, setFromDate)}
                                            value={fromDate ? getHour(new Date(fromDate)) : ''}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="toDate">
                                        <Form.Label>{language.ToDate}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            ref={toDateRef}
                                            onChange={event => handleDateChange(event, toDate, setToDate)}
                                            value={toDate ? getDate(toDate) : ''}
                                        />
                                        <Form.Control
                                            type="time"
                                            onChange={event => handleTimeChange(event, toDate, setToDate)}
                                            value={toDate ? getHour(new Date(toDate)) : ''}
                                        />
                                    </Form.Group>
                                    <Button className="Submit_btn" onClick={handleSubmit} variant="primary">{language.Submit}</Button>
                                </Form>
                            </div>)
                        }
                    </div>
                    {dateBtn && (<p className="data_label">{dateBtn}</p>)}
                    <h4 className='module__title'>{language.AlarmsDashboard}</h4>
                    <div className="main-box">
                        <div className="dashboard-settings">
                            {
                                (items.length > 0) && (items.dashboard1.length + items.dashboard2.length) > 15 && (<IoIosSettings size={20} style={{ fill: '#727BBE' }} />)
                            }
                        </div>
                        <h4 className="dashboard-subtitle">{language.TVM}</h4>
                        {(items?.dashboard1?.length === 0 && items?.dashboard2?.length === 0) && (
                            <p className="date">{language.NoData}</p>
                        )}
                        <div className={`cards-container ${isDraggable ? 'show-droppable' : ''}`}>
                            <div className="main-droppable">
                                <Droppable
                                    id="dashboard1"
                                    items={items.dashboard1}
                                    items2={items.dashboard2}
                                    cardType={isRealTime || (!isRealTime && dateState === 0) ? "Alerts" : "InformativeAlerts"}
                                    language={language}
                                    handleCardDetails={handleCardDetails}
                                    key="dashboard1"
                                    currentDashboard={"TVMDashboardAlarms"}
                                    isDraggable={isDraggable}
                                    setIsDraggable={setIsDraggable}
                                    setIsLoading={setIsLoading}
                                    setIsRealTime={setIsRealTime}
                                    dateState={dateState}
                                />
                            </div>
                            {
                                isDraggable && (
                                    <div className="droppable-wrapper">
                                        <Droppable
                                            id="dashboard2"
                                            items={items.dashboard2}
                                            cardType={isRealTime || (!isRealTime && dateState === 0) ? "Alerts" : "InformativeAlerts"}
                                            language={language}
                                            handleCardDetails={handleCardDetails}
                                            key="dashboard2"
                                            currentDashboard={"TVMDashboardAlarms"}
                                            isDraggable={isDraggable}
                                            setIsDraggable={setIsDraggable}
                                            isMoreCards={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>

                </DndContext>
            </section>
            <section className="main-container-app" style={{ minHeight: 'auto' }}>
                <div className="main-box">
                    <h4 className='dashboard-subtitle'>{language.TableAlarms}</h4>
                    {allAlarm && Object.keys(allAlarm).length > 0 && <DashboardTableAlarm device_data={allAlarm} header={headerAllAlarm} handleDetails={handleDetails} language={language} />}
                    {Object.keys(allAlarm).length === 0 && 
                    <p className="date">{language.NoData}</p>}
                </div>
            </section>
            <Footer />
            <Modal size="xl" backdrop={false} animation={true} keyboard={true} show={showModal} onHide={handleModalClose(ModalType.MODAL_CARD_DETAILS, data[0]?.deviceCode)}>
                <Modal.Header closeButton>
                    <Modal.Title>{language.Alarms}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data.length > 0 && <DashBoardTable device_data={data} language={language} />}
                </Modal.Body>
            </Modal>
            <Modal size="xl" backdrop={false} animation={true} keyboard={true} show={showModalDetailsTable} onHide={handleModalClose(ModalType.MODAL_TABLE_DETAILS)}>
                <Modal.Header closeButton>
                    <Modal.Title>{language.Alarms}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data.length > 0 && <DashboardDetailAlarmTable device_data={data} language={language} />}
                </Modal.Body>
            </Modal>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default DashboardAlarm;