import React, { useEffect, useRef, useState } from "react";
import { IoIosSettings } from "react-icons/io";

import { Form, Button, Spinner } from "react-bootstrap";

import {
    DndContext,
} from "@dnd-kit/core";
import "./Dashboard.css";
import Droppable from "./Droppable/Droppable";

//Graphics references
import GraphHeatmap from "./Graphics/GraphHeatmap";
import BarChart from "./Graphics/LineChart";
//API references
import postDashboardFinancial from "../../api/Dashboard/postDashboardFinancial";
import MultipleBarChart from "./Graphics/MultipleBarChart";
import useDragAndDrop from "../../Hooks/useDragAndDrop/useDragAndDrop";
import handleTimeChange from "../../Common/HandleTimeChange";
import getHour from "../../Common/GetHour";
import handleDateChange from "../../Common/HandleDateChange";
import getDate from "../../Common/GetDate";
import Footer from "../../Common/Footer";

function DashboardTransactions() {
    const [isDraggable, setIsDraggable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const formatDate = (date, languageCode) => {
        return date.toLocaleDateString(languageCode, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };
    /******************** START: DATE STATE ****************************************************/
    const DateState = {
        TODAY: 0,
        WEEK_DATE: 1,
        MONTH_DATE: 2,
        YEAR_TO_DATE: 3,
        PROGRAM_TO_DATE: 4,
        CUSTOM_DATES: 5
    };
    /******************** END: DATE STATE *********************************************************/
    // #region get localStorage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const language = JSON.parse(localStorage.getItem('language'));
    // #endregion

    const getCurrentMonthDateRange = () => {
        //local date
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        startOfMonth.setHours(0, 0, 0, 0);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        //UTC date
        const startOfMonthUTC = startOfMonth.toISOString();
        const endOfMonthUTC = endOfMonth.toISOString();

        return { startOfMonthUTC, endOfMonthUTC, startOfMonth, endOfMonth };
    };

    const getCurrentYearDateRange = () => {
        const currentDate = new Date();
        const firstDayOfYear = new Date();
        //Local date
        firstDayOfYear.setFullYear(currentDate.getFullYear() - 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        const lastDayOfYear = currentDate;
        lastDayOfYear.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfYear.toISOString();
        const endOfWeekUTC = lastDayOfYear.toISOString();

        return { startOfWeekUTC, endOfWeekUTC, firstDayOfYear, lastDayOfYear };
    }

    const { startOfMonth, endOfMonth } = getCurrentMonthDateRange();

    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);

    //const [items, setItems] = useState([]);
    const [heatMapItem, setHeatMapItem] = useState([]);
    const [lineMapItem, setLineMapItem] = useState([]);
    const [barChart, setBarChart] = useState([]);
    const [countBillsAndCoins, seCountBillsAndCoins] = useState([]);
    const [isTVM, setIsTVM] = useState(true);
    const [dateBtn, setDateBtn] = useState([]);
    const [dateFinancial, SetDateFinancial] = useState(
        `${language.FromDate}: ${formatDate(startOfMonth, languageCode)} 
        ${language.ToDate}: ${formatDate(endOfMonth, languageCode)}`
    );

    const initialItems = { dashboard1: [], dashboard2: [] };
    const { items, sensors, handleDragOver, handleDragEnd, setItems } = useDragAndDrop(initialItems);

    /******************** START: state for DATE HANDLE **********************************/
    const [customDate, setCustomDate] = useState(false);
    const [dateState, setDateState] = useState(DateState.MONTH_DATE);
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    /********************** END: state for DATE HANDLE **********************************/


    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const tenantsDB = currentUser.data.currentTenant;
                /******************* START: Gets year ********************************************/
                const { startOfWeekUTC, endOfWeekUTC } = getCurrentYearDateRange();
                /******************* END: Gets year ********************************************/
                /******************* START: Gets Month *****************************************/
                const { startOfMonthUTC, endOfMonthUTC } = getCurrentMonthDateRange();
                /******************* END: Gets Month *****************************************/
                /******************* START: Formating Date *****************************************/
                let fromDateFormatted = fromDate;
                let toDateFormatted = toDate;
                if (dateState === 5) {
                    fromDateFormatted = fromDateFormatted.toISOString();
                    toDateFormatted = toDateFormatted.toISOString();
                }
                const response = await postDashboardFinancial(
                    {
                        tenantsDB,
                        heatMap_data: {
                            fromDate: startOfWeekUTC,
                            toDate: endOfWeekUTC,
                            isTVM
                        },
                        summaryGraph_SalesProducts: {
                            fromDate: startOfWeekUTC,
                            toDate: endOfWeekUTC,
                            typeGraph: 0
                        },
                        summaryGraph_Sales: {
                            fromDate: startOfWeekUTC,
                            toDate: endOfWeekUTC,
                            typeGraph: 1
                        },
                        summaryGraph_CountBillsAndCoins: {
                            fromDate: startOfWeekUTC,
                            toDate: endOfWeekUTC,
                            typeGraph: 2
                        },
                        card: {
                            fromDate: (fromDateFormatted ? fromDateFormatted : startOfMonthUTC),
                            toDate: (toDateFormatted ? toDateFormatted : endOfMonthUTC)
                        },
                    });

                const dashboardCard = response.cardFinancial ? response.cardFinancial[0] : {};
                const dashboardCardUnsorted = response.cardFinancialUnsorted ? response.cardFinancialUnsorted[0] : {};

                const heatmap_financial = response.heatMap;
                const salesIncome = response.SummaryGraph_Sales;
                const salesProducts = response.SummaryGraph_salesProducts;
                const countBillsAndCoins = response.SummaryGraph_CountBillsAndCoins;
                setHeatMapItem(heatmap_financial);
                setLineMapItem(salesIncome);
                setBarChart(salesProducts);
                seCountBillsAndCoins(countBillsAndCoins);
                //array for card
                const dashboards = Array.from({ length: 2 }, () => []);
                let dashboardEntries = Object.entries(dashboardCard);
                let dashboardEntriesUnsorted =
                    Object.keys(dashboardCardUnsorted).length > 0
                        ? Object.entries(dashboardCardUnsorted)
                        : [];

                let cardCount = 1;
                if (dashboardEntriesUnsorted.length === 0) {
                    const allEntries = Object.entries(dashboardCard);
                    dashboardEntries = allEntries.slice(0, 15);
                    dashboardEntriesUnsorted = allEntries.slice(15);
                }

                [dashboardEntries, dashboardEntriesUnsorted].forEach((entries, index) => {
                    entries.forEach(([name, total]) => {
                        dashboards[index].push({
                            id: cardCount++,
                            name,
                            total
                        });
                    });
                });

                const [dashboard1, dashboard2] = dashboards;
                setItems({ dashboard1, dashboard2 });
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [isTVM, dateState]);

    const handleByTvm = () => {
        setIsTVM(true);
    }

    const handleByStation = () => {
        setIsTVM(false);
    }
    /**************** START: DATE HANDLE *************************************/

    const handleToday = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        //UTC date
        const startOfDayUTC = startOfDay.toISOString();
        const endOfDayUTC = endOfDay.toISOString();
        setFromDate(startOfDayUTC);
        setToDate(endOfDayUTC);
        setDateState(DateState.TODAY);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(startOfDay, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfDay, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay, languageCode)}`
        );
    }

    const handleWeekDate = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfWeek.toISOString();
        const endOfWeekUTC = lastDayOfWeek.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setDateState(DateState.WEEK_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek, languageCode)}`
        );
    }

    const handleMonthDate = async () => {
        setCustomDate(false);
        const { startOfMonthUTC, endOfMonthUTC, startOfMonth, endOfMonth } = getCurrentMonthDateRange();
        setFromDate(startOfMonthUTC);
        setToDate(endOfMonthUTC);
        setDateState(DateState.MONTH_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(startOfMonth, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfMonth, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfMonth, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfMonth, languageCode)}`
        );
    }

    const handleYearToDate = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //Local date
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        const lastDayOfYear = currentDate;
        lastDayOfYear.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfYear.toISOString();
        const endOfWeekUTC = lastDayOfYear.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setDateState(DateState.YEAR_TO_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(firstDayOfYear, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfYear, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear, languageCode)}`
        );
    }

    const handleCustomDates = () => {
        setToDate(() => {
            const initialDate = new Date();
            initialDate.setHours(23, 59, 59, 999);
            return initialDate;
        });
        setFromDate(() => {
            const initialDate = new Date();
            initialDate.setHours(0, 0, 0, 0);
            return initialDate;
        });
        setCustomDate(true);
        setDateBtn("");
    }

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const selectedFromDate = fromDateRef.current.value;
        const selectedToDate = toDateRef.current.value;
        //select date
        const [yearFrom, monthFrom, dayFrom] = selectedFromDate.split('-');
        const [hoursFrom, minutesFrom, secondsFrom] = getHour(new Date(fromDate)).split(':');
        const [yearTo, monthTo, dayTo] = selectedToDate.split('-');
        const [hoursTo, minutesTo, secondsTo] = getHour(new Date(toDate)).split(':');
        const customFromDate = new Date(yearFrom, monthFrom - 1, dayFrom, hoursFrom, minutesFrom, secondsFrom, 0);
        const customToDate = new Date(yearTo, monthTo - 1, dayTo, hoursTo, minutesTo, secondsTo, 999);

        setDateState(DateState.CUSTOM_DATES);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(customFromDate, languageCode)} 
            ${language.ToDate}: ${formatDate(customToDate, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(customFromDate, languageCode)}
            ${language.ToDate}: ${formatDate(customToDate, languageCode)}`
        );
    };
    /****************** END: DATE HANDLE ***********************************/

    return (
        <>
            <div className="dashboards_container">
                <section className="main-container-app" style={{ minHeight: 'auto' }}>
                    <div className="dashboard-container">
                        <div className="dashboard-btn-container">
                            <button onClick={handleToday} className="dashboard-date-btn">{language.Today}</button>
                            <button onClick={handleWeekDate} className="dashboard-date-btn">{language.WeekToDate}</button>
                            <button onClick={handleMonthDate} className="dashboard-date-btn">{language.MonthToDate}</button>
                            <button onClick={handleYearToDate} className="dashboard-date-btn">{language.YearToDate}</button>
                            <button onClick={handleCustomDates} className="dashboard-date-btn">{language.CustomDates}</button>
                        </div>
                        {customDate && (
                            <div className="dashboard-box-date">
                                <Form>
                                    <Form.Group controlId="fromDate">
                                        <Form.Label>{language.FromDate}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            ref={fromDateRef}
                                            onChange={event => handleDateChange(event, fromDate, setFromDate)}
                                            value={fromDate ? getDate(fromDate) : ''}
                                        />
                                        <Form.Control
                                            type="time"
                                            onChange={event => handleTimeChange(event, fromDate, setFromDate)}
                                            value={fromDate ? getHour(new Date(fromDate)) : ''}
                                        />
                                    </Form.Group>
                                    <Form.Group controlId="toDate">
                                        <Form.Label>{language.ToDate}</Form.Label>
                                        <Form.Control
                                            type="date"
                                            ref={toDateRef}
                                            onChange={event => handleDateChange(event, toDate, setToDate)}
                                            value={toDate ? getDate(toDate) : ''}
                                        />
                                        <Form.Control
                                            type="time"
                                            onChange={event => handleTimeChange(event, toDate, setToDate)}
                                            value={toDate ? getHour(new Date(toDate)) : ''}
                                        />
                                    </Form.Group>
                                    <Button className="Submit_btn" onClick={handleSubmit} variant="primary">{language.Submit}</Button>
                                </Form>
                            </div>
                        )}
                    </div>
                    {dateBtn && (<p className="data_label">{dateFinancial}</p>)}

                    <DndContext
                        sensors={sensors}
                        onDragEnd={handleDragEnd}
                        onDragOver={handleDragOver}
                    >
                        <div className="main-box">
                            <div className="dashboard-settings">
                                {(Object.keys(items).length > 0) && ((items.dashboard1.length + items.dashboard2.length) > 15) && (
                                    <IoIosSettings size={20} style={{ fill: '#727BBE' }} />
                                )}
                            </div>
                            <h4 className="dashboard-subtitle">{language.Financial}</h4>
                            {(items?.dashboard1?.length === 0 && items?.dashboard2?.length === 0) && (
                                <p className="date">{language.NoData}</p>
                            )}
                            <div className={`cards-container ${isDraggable ? 'show-droppable' : ''}`}>
                                <div className="main-droppable">
                                    <Droppable
                                        id="dashboard1"
                                        items={items.dashboard1}
                                        items2={items.dashboard2}
                                        cardType={"Financial"}
                                        language={language}
                                        key="dashboard1"
                                        currentDashboard={"TVMDashboardTransactions"}
                                        currency={currency}
                                        isDraggable={isDraggable}
                                        setIsDraggable={setIsDraggable}
                                        setIsLoading={setIsLoading}
                                    />
                                </div>
                                {
                                    isDraggable && (
                                        <div className="droppable-wrapper">
                                            <Droppable
                                                id="dashboard2"
                                                items={items.dashboard2}
                                                cardType={"Financial"}
                                                language={language}
                                                key="dashboard2"
                                                currentDashboard={"TVMDashboardTransactions"}
                                                currency={currency}
                                                isDraggable={isDraggable}
                                                setIsDraggable={setIsDraggable}
                                                isMoreCards={true}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </DndContext>
                </section>

                {lineMapItem.length > 0 || barChart.length > 0 || countBillsAndCoins.length > 0 ? (
                    <section className="main-container-app" style={{ paddingTop: 0, minHeight: 'auto' }}>
                        <div className="main-box">
                            <h4 className='dashboard-subtitle'>{language.SummaryGraph}</h4>
                            <div className="graph_container">
                                {lineMapItem.length > 0 && (
                                    <BarChart data={lineMapItem} title={language.Sales} currency={currency} languageCode={languageCode} />
                                )}
                                {barChart.length > 0 && (
                                    <MultipleBarChart data={barChart} typeGraph={1} title={language.ProductsSales} currency={currency} languageCode={languageCode} />
                                )}
                                {countBillsAndCoins.length > 0 && (
                                    <MultipleBarChart data={countBillsAndCoins} typeGraph={2} title={language.PaymentMethodBalance} currency={currency} languageCode={languageCode} />
                                )}
                            </div>
                        </div>
                    </section>
                ) : null}

                {heatMapItem.length > 0 && (
                    <section className="main-container-app" style={{ paddingTop: 0, minHeight: 'auto' }}>
                        <div className="main-box">
                            <h4 className='dashboard-subtitle'>{language.SalesHeatMap}</h4>
                            <div className="container-buttons">
                                <button className="button-dashboard" onClick={handleByTvm}>{language.ByTVM}</button>
                                <button className="button-dashboard" onClick={handleByStation}>{language.ByStation}</button>
                            </div>
                            <GraphHeatmap data={heatMapItem} isTVM={isTVM} language={language} />
                        </div>
                    </section>
                )}
                <Footer />

            </div>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
        </>
    );
}

export default DashboardTransactions;