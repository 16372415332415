import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { signOut, getCurrentUser } from 'aws-amplify/auth';
import GetPermission from '../auth/GetPermission';

import { IconContext } from 'react-icons';
import * as RiIcons from 'react-icons/ri';

import { SidebarData } from './SideBarData';
import './css/NavBar.css';
import ProfileOverlay from './ProfileOverlay';
import CreateUserModal from '../page/UserManager/CreateUser';
import TenantsChangeModal from '../page/UserManager/TenantsChangeModal';

function Navbar({ showSubn, setShowSubn }) {
    const [authenticated, setAuthenticated] = useState(false);
    const [subnav, setSubnav] = useState([]);
    const [expanded, setExpanded] = useState({});
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const language = JSON.parse(localStorage.getItem('language'));
    const [showProfileOverlay, setShowProfileOverlay] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const signOutRef = useRef(null);
    const profileOverlayRef = useRef(null);
    const [editedUserData, setEditedUserData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const subnavRef = useRef(null);
    const [showTenantsModal, setShowTenantsModal] = useState(false);

    const showSubnav = (item, index) => {
        if (deepEqual(item.subNav, subnav)) {
            setShowSubn(!showSubn);
        } else {
            setShowSubn(true);
            setSubnav(item.subNav);
            setExpanded({ ...expanded, [index]: !expanded[index] });
        }
    };

    // Manages if clicks outside profile overlay and hide it
    const handleOutsideClick = (e) => {
        if (
            profileOverlayRef.current &&
            !profileOverlayRef.current.contains(e.target) &&
            !signOutRef.current.contains(e.target)
        ) {
            setShowProfileOverlay(false);
        }
    };

    // Manages if clicks outside subnav and hide it
    const handleOutsideNavbarClick = (e) => {
        if (subnavRef.current && !subnavRef.current.contains(e.target)) {
            setShowSubn(false);
        }
    };

    // Checks every click to update profile overlay
    useEffect(() => {
        if (showProfileOverlay) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [showProfileOverlay]);

    // Checks every click to update show subnav
    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideNavbarClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideNavbarClick);
        };
    }, []);

    function isObject(obj) {
        return obj !== null && typeof obj === 'object';
    }

    function deepEqual(obj1, obj2) {
        if (obj1 === obj2) {
            return true;
        }

        if (!isObject(obj1) || !isObject(obj2)) {
            return false;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (const key of keys1) {
            if (!keys2.includes(key)) {
                return false;
            }

            if (!deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    useEffect(() => {
        checkAuthentication();
    }, []);

    const checkAuthentication = async () => {
        try {
            const currentUser = await getCurrentUser();
            setAuthenticated(!!currentUser);
        } catch (error) {
            setAuthenticated(false);
        }
    };

    if (!authenticated || !currentUser || !currentUser.data?.currentTenant || !language) {
        return null;
    }

    // Returns fixed position for the profile overlay
    const handleProfileClick = () => {
        if (signOutRef.current) {
            const rect = signOutRef.current.getBoundingClientRect();

            setPosition({
                x: rect.right,
                y: rect.top
            });
            setShowProfileOverlay(true);
        }
    };

    const handleProfileModal = () => {
        const userToEdit = currentUser.data.userData;
        setEditedUserData(userToEdit);
        setIsEditing(true);
        setShowCreateUserModal(true);
    };

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }} >

                <div className="nav-menu active">
                    <ul className="nav-menu-items">
                        {SidebarData.map((item, index) => (
                            <NavItem
                                item={item}
                                index={index}
                                key={index}
                                showSubnav={showSubnav}
                                expanded={expanded[index]}
                                showProfileOverlay={showProfileOverlay}
                                setShowProfileOverlay={setShowProfileOverlay}
                                handleProfileClick={handleProfileClick}
                                signOutRef={item.nameModule === 'SignOut' ? signOutRef : null}
                            />
                        ))}
                    </ul>
                </div>

                <div
                    ref={subnavRef}
                    className={`sub-menu ${showSubn ? 'show' : ''}`}>
                    {subnav.map((item, index) => {
                        const isAllowed = GetPermission(currentUser, item.nameModule, "readAllow");
                        return (isAllowed || !item.nameModule) ? (
                            <SubMenu item={item} key={index} />
                        ) : null;
                    })}
                </div>

            </IconContext.Provider>

            {showProfileOverlay && (
                <div
                    ref={profileOverlayRef}
                    style={{
                        position: 'absolute',
                        top: position.y,
                        left: position.x,
                    }}
                >
                    <ProfileOverlay
                        show={true}
                        onClose={() => setShowProfileOverlay(false)}
                        position={position}
                        handleProfileModal={handleProfileModal}
                        setShowTenantsModal={() => setShowTenantsModal(true)}
                    />
                </div>
            )
            }

            <CreateUserModal
                show={showCreateUserModal}
                onHide={() => setShowCreateUserModal(false)}
                editedUserData={editedUserData}
                editing={isEditing}
            />

            <TenantsChangeModal
                show={showTenantsModal}
                onHide={() => setShowTenantsModal(false)}
                
            />
        </>
    );
}

function NavItem({ item, index, showSubnav, handleProfileClick, signOutRef }) {
    return (
        <li className="nav-text">
            <Link to={item.path ? item.path : '#'}
                ref={signOutRef}
                onClick={item.nameModule === 'SignOut' ? (event) => handleProfileClick(event) : () => showSubnav(item, index)}>
                <div>{item.icon}</div>
                <div>{item.nameModule === 'SignOut' ? '' : item.title}</div>
            </Link>
        </li>
    );
}

function SubMenu({ item }) {
    const [expanded, setExpanded] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const toggleExpand = () => {
        setExpanded(!expanded);
    };

    return (
        <div>
            <Link to={item.path} className="nav-subtitle" onClick={toggleExpand}>
                <div className="submenu__icon">
                    {item.subNav &&
                        (expanded ? (
                            <RiIcons.RiArrowUpSFill style={{ color: '#5b5b5b' }} />
                        ) : (
                            <RiIcons.RiArrowDownSFill style={{ color: '#5b5b5b' }} />
                        ))}
                </div>
                <span>{item.title}</span>
            </Link>

            {expanded &&
                item.subNav &&
                item.subNav.map((subItem, index) => {
                    const isAllowed = GetPermission(currentUser, subItem.nameModule, "readAllow");

                    return isAllowed || subItem.subNav ? (
                        <div key={index} className="nav-submenu">
                            {subItem.subNav ? (
                                <div style={{ marginLeft: '1rem' }}><SubMenu item={subItem} /></div>
                            ) : (
                                <Link to={subItem.path} className="nav-subtext">
                                    <span>{subItem.title}</span>
                                </Link>
                            )}
                        </div>
                    ) : null;
                })}
        </div>
    );
}

export default Navbar;